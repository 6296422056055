import axios from "axios";
import { BillingTitle } from "./types";

//! LOG
export interface GetLogDataParam {
  skip?: number;
  limit?: number;
  userId: string;
  startDate: Date | null;
  endDate: Date | null;
  compCode: string;
  deptCode: string;
  favorCode: number[];
}

export interface GetLogDataResult {
  totalCount: number;
  result: LogData[];
}

export interface LogData {
  _id: string;
  listId: string;
  question: string;
  keyword: string;
  answer: string;
  source: SourceInfo[];
  createdBy: string;
  createdDate: string;
  updatedDate: string;
  QuestionTokens: number;
  AnswerTokens: number;
  latency: number;
  deleteFlag: boolean;
  filtered: boolean;
  bingSearch: boolean;
  model: string;
  temperature: number;
  favor: number;
  finishReason: string;
  compCode: string;
  deptCode: string;
}

export interface SourceInfo {
  bingType: string;
  bingSourceUrl: string;
  bingSourceName: string;
  bingSourceContext: string;
  bingSourceProvider: string;
}

export interface GetEventLogDataParam {
  skip?: number;
  limit?: number;
  userId: string;
  startDate: Date | null;
  endDate: Date | null;
  logLevel: string;
  event: string;
}

export interface GetEventLogDataResult {
  totalCount: number;
  result: EventLogData[];
}

export interface EventLogData {
  _id: string;
  userId: string;
  logLevel: string;
  event: string;
  activity: string;
  error_detail: string;
  timestamp: string;
  latency: number;
}

//! log mgmt
export const getLogData = async (param: GetLogDataParam) => {
  const res = await axios.post(`/log/logSearch`, param);

  return res.data;
};

export const downloadLogData = async (param: GetLogDataParam) => {
  await axios
    .post(`/log/logDownload`, param, { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data]);

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `log.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
};

export const getEventLogData = async (param: GetEventLogDataParam) => {
  const res = await axios.post(`/log/eventlogSearch`, param);

  return res.data;
};

export const downloadEventLogData = async (param: GetEventLogDataParam) => {
  await axios
    .post(`/log/eventlogDownload`, param, { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data]);

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `event_log.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
};

export interface GetTokenParam {
  startDate: Date;
  endDate: Date;
  companyList?: string[];
  model: number; // 0: ALL, 1:Assistant, 2:MyGPTs, 3:DALLE
  // model: number; // v3.5 -> 1 , v4.0 -> 2 , all -> 3
}

export interface GetBillingNameList {
  payload: {
    startDate: Date;
    endDate: Date;
    model: number; // 0: ALL, 1:Assistant, 2:MyGPTs, 3:DALLE
  };
  title: BillingTitle;
}

export interface GetTokenLogResult {
  totalAnswerTokens: number;
  totalQuestionTokens: number;
  totalTokens: number;
  result: TokenLogData[];
}

export interface TokenLogData {
  total_answer_tokens: number;
  total_question_tokens: number;
  total_tokens: number;
  name: string;
  code: string;
  model: string;
}

export type GetTokenCompanyResult = TokenCompanyData[];

export interface TokenCompanyData {
  compCode: string;
  compName: string;
  hasLog: boolean;
}

export interface BillingNameListResult {
  code: string;
  name: string;
  hasLog: boolean;
}

export type GetBillingNameListResult = BillingNameListResult[];
export interface GetTokenLog {
  payload: {
    startDate?: Date;
    endDate?: Date;
    model: number; // 0: ALL, 1:Assistant, 2:MyGPTs, 3:DALLE
    filterList: string[];
    filterType?: string;
  };
  title: BillingTitle;
}
//! billing
export const getTokenLogList = async (param: GetTokenLog) => {
  const { payload, title } = param;
  const res = await axios.post(`/log/tokenlogSearch`, payload);
  let newResult = [];
  if (title === "company") {
    newResult = res.data.result.map((item: any) => ({
      total_answer_tokens: item.total_answer_tokens,
      total_question_tokens: item.total_question_tokens,
      total_tokens: item.total_tokens,
      model: item.model,
      code: item.compCode,
      name: item.compName,
    }));
  }
  if (title === "department") {
    newResult = res.data.result.map((item: any) => ({
      total_answer_tokens: item.total_answer_tokens,
      total_question_tokens: item.total_question_tokens,
      total_tokens: item.total_tokens,
      model: item.model,
      code: item.deptCode,
      name: item.deptName,
    }));
  }
  if (title === "user") {
    newResult = res.data.result.map((item: any) => ({
      total_answer_tokens: item.total_answer_tokens,
      total_question_tokens: item.total_question_tokens,
      total_tokens: item.total_tokens,
      model: item.model,
      code: item.userId,
      name: item.userName,
    }));
  }

  return { ...res.data, result: newResult };
};

export const getTokenCompany = async (param: GetTokenParam) => {
  const res = await axios.post(`/log/compTokenList`, param);
  return res.data;
};

export const getBillingNameList = async (param: GetBillingNameList) => {
  const { title, payload } = param;
  const apiUrls = {
    company: "/log/compTokenList",
    department: "/log/deptTokenList",
    user: "/log/userTokenList",
  };
  const res = await axios.post(apiUrls[title], payload);

  if (title === "company") {
    return res.data.map((item: any) => ({
      code: item.compCode,
      name: item.compName,
      hasLog: item.hasLog,
    }));
  }
  if (title === "department") {
    return res.data.map((item: any) => ({
      code: item.deptCode,
      name: item.deptName,
      hasLog: item.hasLog,
    }));
  }
  if (title === "user") {
    return res.data.map((item: any) => ({
      code: item.userId,
      name: item.userName,
      hasLog: item.hasLog,
    }));
  }

  return res.data;
};
