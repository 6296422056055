import { createReducer } from "typesafe-actions";
import { LoginAction, LoginState } from "./types";
import produce from "immer";
import {
  aadLoginAsync,
  agreeTermsAndConditionsAsync,
  getSettingAsync,
  logout,
  openPopup,
  setIsShowSidebar,
  setTheme,
  updateSettingAsync,
} from "./actions";
import moment from "moment";
import localStorageManager from "../../utils/localStorageManager";
import { ISetting } from "./api";
import AUTH from "../../constants/auth";

const { setStorage } = localStorageManager;
const { accessToken, refreshToken, expireTime, userId, dataAnalysis } = AUTH;

const initialSettings: ISetting = {
  _id: "",
  userId: "",
  fontSize: "medium",
  font: "noto sans kr",
  chatType: "chatting",
  temperature: 0.7,
};
const initialUserInfo = {
  userId: "",
  userName: "",
  access_token: "",
  refresh_token: "",
  expires_delta: "",
  isAgreed: false,
  isAdmin: false,
  deptCode: "",
  compCode: "",
  canAnalyzeFile: false,
  baseUserSettings: initialSettings,
};

const initialState: LoginState = {
  userInfo: initialUserInfo,
  // ❓ 모달을 컨트롤하고 있는게 맞는지?
  openPopup: false,
  // 🎨change theme
  teamsTheme: "default", // 클래식, 밝게, 운영체제에 맞게(?)
  isShowSidebar: true,
  // teamsTheme: "dark",
  // teamsTheme: "contrast", //contrast
};

const auth = createReducer<LoginState, LoginAction>(initialState)
  .handleAction(aadLoginAsync.success, (state, { payload }) => {
    const { access_token, refresh_token, canAnalyzeFile } = payload;
    setStorage(accessToken, access_token);
    setStorage(refreshToken, refresh_token);
    setStorage(userId, payload.userId);
    setStorage(
      expireTime,
      moment(moment.utc(payload.expires_delta).toDate()).format("YYYYMMDDHHmm")
    );
    setStorage(dataAnalysis, canAnalyzeFile.toString());

    return produce(state, (draft) => {
      draft.userInfo = {
        ...payload,
      };
    });
  })
  .handleAction(agreeTermsAndConditionsAsync.success, (state, action) => {
    return produce(state, (draft) => {
      draft.userInfo.isAgreed = action.payload.isAgreed;

      if (action.payload.isAgreed) {
        draft.openPopup = false;
      }
    });
  })
  .handleAction(openPopup, (state, action) => {
    return produce(state, (draft) => {
      draft.openPopup = action.payload.open;
    });
  })
  .handleAction(updateSettingAsync.success, (state, action) => {
    return produce(state, (draft) => {
      draft.userInfo.baseUserSettings = action.payload;
    });
  })
  .handleAction(getSettingAsync.success, (state, action) => {
    return produce(state, (draft) => {
      draft.userInfo.baseUserSettings = action.payload;

      // draft.userInfo.gptModels = action.payload.gptModels;
    });
  })
  .handleAction(setTheme, (state, action) => {
    return produce(state, (draft) => {
      draft.teamsTheme = action.payload.theme;
    });
  })
  .handleAction(setIsShowSidebar, (state, action) => {
    return produce(state, (draft) => {
      draft.isShowSidebar = action.payload;
    });
  });

export default auth;
