import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AiOutlinePercentage } from "react-icons/ai";
import { selectTeamsTheme } from "../../../../features/auth";
import ThBtn from "../../../components/userInterface/buttons/ThBtn";
import {
  BsSortAlphaDown,
  BsSortNumericDown,
  BsSortAlphaDownAlt,
  BsSortNumericDownAlt,
} from "react-icons/bs";
import {
  TokenLogData,
  selectTokenLogData,
  selectTotalTokenData,
} from "../../../../features/log";
import { selectTokenListLoading } from "../../../../features/ui_loading";
import { ClipLoader } from "react-spinners";

type SortName =
  | "name"
  | "total_question_tokens"
  | "total_answer_tokens"
  | "total_tokens";

export const TokenLogTable = ({ title }: { title: string }) => {
  const teamsTheme = useSelector(selectTeamsTheme);
  const tokenLogList = useSelector(selectTokenLogData);
  const allTokenData = useSelector(selectTotalTokenData);
  const tokenLoading = useSelector(selectTokenListLoading);

  const [tokenLog, setTokenLog] = useState<TokenLogData[]>([]);
  const [tokenPercent, setTokenPercent] = useState({
    questionToken: true,
    answerToken: true,
    sumToken: true,
  });
  const [sortItem, setSortItem] = useState<{
    [key in SortName]: { state: boolean; sort: "asc" | "desc" };
  }>({
    name: { state: false, sort: "asc" },
    total_question_tokens: { state: false, sort: "asc" },
    total_answer_tokens: { state: false, sort: "asc" },
    total_tokens: { state: true, sort: "desc" },
  });

  useEffect(() => {
    sortChangeHandler("total_tokens", "asc", true);

    setTokenPercent({
      questionToken: true,
      answerToken: true,
      sumToken: true,
    });
  }, [tokenLogList]);

  //! sort UI 변경
  const sortChangeHandler = (
    item: SortName,
    sort: "asc" | "desc",
    logChange?: boolean
  ) => {
    const sortArr: SortName[] = [
      "name",
      "total_question_tokens",
      "total_answer_tokens",
      "total_tokens",
    ];

    if (sortItem[item].state) {
      sortItem[item].sort = sort === "desc" ? "asc" : "desc";
    } else {
      sortItem[item].state = !sortItem[item].state;
      sortItem[item].sort = sort === "desc" ? "asc" : "desc";
    }

    for (const data of sortArr) {
      if (data !== item) {
        sortItem[data].state = false;
        if (logChange) {
          sortItem[data].sort = "asc";
        }
      }
    }

    setSortItem({ ...sortItem });
    sortChangelog(item, sortItem[item].sort);
  };

  //! sort에 따른 로그데이터 변경
  const sortChangelog = (item: SortName, sort: "asc" | "desc") => {
    let sortedData: TokenLogData[] = tokenLogList.slice();

    if (sort === "desc") {
      sortedData = sortedData.sort((a, b) =>
        a[item] > b[item] ? -1 : a[item] > b[item] ? 1 : 0
      );
    } else {
      sortedData = sortedData.sort((a, b) =>
        a[item] < b[item] ? -1 : a[item] > b[item] ? 1 : 0
      );
    }

    setTokenLog(sortedData);
  };

  //! percent 변경
  const percentChangeHandler = (allToken: number, token: number) => {
    const percent = (token / allToken) * 100;
    return `${percent.toFixed(2)}%`;
  };

  return (
    <>
      <table className={`page_table page_table-${teamsTheme}`} width="500">
        <thead className={`thead_${teamsTheme}`}>
          <tr>
            <th title={title}>
              <span>{title}</span>{" "}
              <ThBtn
                icon={<BsSortAlphaDownAlt />}
                changeIcon={<BsSortAlphaDown />}
                onClickHandler={() =>
                  sortChangeHandler("name", sortItem["name"].sort)
                }
                iconChangeFilter={sortItem.name.sort === "asc"}
                classChangeFilter={!sortItem.name.state}
              />
            </th>
            <th title="Model">Model</th>
            <th title="Question token">
              <span>Question token</span>{" "}
              <ThBtn
                icon={<BsSortNumericDownAlt />}
                changeIcon={<BsSortNumericDown />}
                onClickHandler={() =>
                  sortChangeHandler(
                    "total_question_tokens",
                    sortItem["total_question_tokens"].sort
                  )
                }
                iconChangeFilter={sortItem.total_question_tokens.sort === "asc"}
                classChangeFilter={!sortItem.total_question_tokens.state}
              />
              <ThBtn
                icon={<AiOutlinePercentage />}
                onClickHandler={() =>
                  setTokenPercent({
                    ...tokenPercent,
                    questionToken: !tokenPercent.questionToken,
                  })
                }
                classChangeFilter={!tokenPercent.questionToken}
              />
            </th>
            <th title="Answer token">
              <span>Answer token</span>{" "}
              <ThBtn
                icon={<BsSortNumericDownAlt />}
                changeIcon={<BsSortNumericDown />}
                onClickHandler={() =>
                  sortChangeHandler(
                    "total_answer_tokens",
                    sortItem["total_answer_tokens"].sort
                  )
                }
                iconChangeFilter={sortItem.total_answer_tokens.sort === "asc"}
                classChangeFilter={!sortItem.total_answer_tokens.state}
              />
              <ThBtn
                icon={<AiOutlinePercentage />}
                onClickHandler={() =>
                  setTokenPercent({
                    ...tokenPercent,
                    answerToken: !tokenPercent.answerToken,
                  })
                }
                classChangeFilter={!tokenPercent.answerToken}
              />
            </th>
            <th title="Sum token">
              <span>Sum token</span>{" "}
              <ThBtn
                icon={<BsSortNumericDownAlt />}
                changeIcon={<BsSortNumericDown />}
                onClickHandler={() =>
                  sortChangeHandler(
                    "total_tokens",
                    sortItem["total_tokens"].sort
                  )
                }
                iconChangeFilter={sortItem.total_tokens.sort === "asc"}
                classChangeFilter={!sortItem.total_tokens.state}
              />
              <ThBtn
                icon={<AiOutlinePercentage />}
                onClickHandler={() =>
                  setTokenPercent({
                    ...tokenPercent,
                    sumToken: !tokenPercent.sumToken,
                  })
                }
                classChangeFilter={!tokenPercent.sumToken}
              />
            </th>
          </tr>
        </thead>

        <tbody className={`tbody_${teamsTheme}`}>
          {tokenLoading ? (
            <tr className="no_data">
              <td colSpan={5} style={{ textAlign: "center" }}>
                <ClipLoader loading={tokenLoading} size={20} color="#80b1e2" />
              </td>
            </tr>
          ) : (
            <>
              {tokenLog.length > 0 ? (
                tokenLog.map((item) => (
                  <tr>
                    <td className="ellipsis" title={item.name}>
                      {item.name}
                    </td>
                    <td className="ellipsis" title={item.model}>
                      {item.model}
                    </td>
                    <td
                      className="ellipsis"
                      title={`${item.total_question_tokens}`}
                    >
                      {tokenPercent.questionToken
                        ? percentChangeHandler(
                            allTokenData.totalQuestionTokens,
                            item.total_question_tokens
                          )
                        : item.total_question_tokens}
                    </td>
                    <td
                      className="ellipsis"
                      title={`${item.total_answer_tokens}`}
                    >
                      {tokenPercent.answerToken
                        ? percentChangeHandler(
                            allTokenData.totalAnswerTokens,
                            item.total_answer_tokens
                          )
                        : item.total_answer_tokens}
                    </td>
                    <td
                      className="ellipsis"
                      style={{ textAlign: "left" }}
                      title={`${item.total_tokens}`}
                    >
                      {tokenPercent.sumToken
                        ? percentChangeHandler(
                            allTokenData.totalTokens,
                            item.total_tokens
                          )
                        : item.total_tokens}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="no_data">
                  <td colSpan={5} style={{ textAlign: "center" }}>
                    No Data
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};
