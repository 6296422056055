import { AxiosError } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";
import {
  GetBillingNameList,
  GetBillingNameListResult,
  GetEventLogDataParam,
  GetEventLogDataResult,
  GetLogDataParam,
  GetLogDataResult,
  GetTokenCompanyResult,
  GetTokenLog,
  GetTokenLogResult,
  GetTokenParam,
} from "./api";
import { BillingSearchInputsPayload } from "./types";

//! log mgmt
export const GET_LOG_DATA = "admin/GET_LOG_DATA";
export const GET_LOG_DATA_SUCCESS = "admin/GET_LOG_DATA_SUCCESS";
export const GET_LOG_DATA_ERROR = "admin/GET_LOG_DATA_ERROR";

export const getLogDataAsync = createAsyncAction(
  GET_LOG_DATA,
  GET_LOG_DATA_SUCCESS,
  GET_LOG_DATA_ERROR
)<GetLogDataParam, GetLogDataResult, AxiosError>();

export const GET_EVENT_LOG_DATA = "admin/GET_EVENT_LOG_DATA";
export const GET_EVENT_LOG_DATA_SUCCESS = "admin/GET_EVENT_LOG_DATA_SUCCESS";
export const GET_EVENT_LOG_DATA_ERROR = "admin/GET_EVENT_LOG_DATA_ERROR";

export const getEventLogDataAsync = createAsyncAction(
  GET_EVENT_LOG_DATA,
  GET_EVENT_LOG_DATA_SUCCESS,
  GET_EVENT_LOG_DATA_ERROR
)<GetEventLogDataParam, GetEventLogDataResult, AxiosError>();

export const DOWNLOAD_EVENT_LOG_DATA = "admin/DOWNLOAD_EVENT_LOG_DATA";
export const DOWNLOAD_EVENT_LOG_DATA_SUCCESS =
  "admin/DOWNLOAD_EVENT_LOG_DATA_SUCCESS";
export const DOWNLOAD_EVENT_LOG_DATA_ERROR =
  "admin/DOWNLOAD_EVENT_LOG_DATA_ERROR";

export const downloadEventLogDataAsync = createAsyncAction(
  DOWNLOAD_EVENT_LOG_DATA,
  DOWNLOAD_EVENT_LOG_DATA_SUCCESS,
  DOWNLOAD_EVENT_LOG_DATA_ERROR
)<GetEventLogDataParam, any, AxiosError>();

export const DOWNLOAD_LOG_DATA = "admin/DOWNLOAD_LOG_DATA";
export const DOWNLOAD_LOG_DATA_SUCCESS = "admin/DOWNLOAD_LOG_DATA_SUCCESS";
export const DOWNLOAD_LOG_DATA_ERROR = "admin/DOWNLOAD_LOG_DATA_ERROR";

export const downloadLogDataAsync = createAsyncAction(
  DOWNLOAD_LOG_DATA,
  DOWNLOAD_LOG_DATA_SUCCESS,
  DOWNLOAD_LOG_DATA_ERROR
)<GetLogDataParam, any, AxiosError>();

//! billing mgmt
export const GET_TOKEN_LOG = "admin/GET_TOKEN_LOG";
export const GET_TOKEN_LOG_SUCCESS = "admin/GET_TOKEN_LOG_SUCCESS";
export const GET_TOKEN_LOG_ERROR = "admin/GET_TOKEN_LOG_ERROR";

// export const getTokenLogAsync = createAsyncAction(
//   GET_TOKEN_LOG,
//   GET_TOKEN_LOG_SUCCESS,
//   GET_TOKEN_LOG_ERROR
// )<GetTokenParam, GetTokenLogResult, AxiosError>();

export const getTokenLogAsync = createAsyncAction(
  GET_TOKEN_LOG,
  GET_TOKEN_LOG_SUCCESS,
  GET_TOKEN_LOG_ERROR
)<GetTokenLog, GetTokenLogResult, AxiosError>();

export const GET_TOKEN_COMPANY = "admin/GET_TOKEN_COMPANY";
export const GET_TOKEN_COMPANY_SUCCESS = "admin/GET_TOKEN_COMPANY_SUCCESS";
export const GET_TOKEN_COMPANY_ERROR = "admin/GET_TOKEN_COMPANY_ERROR";

export const getTokenCompanyAsync = createAsyncAction(
  GET_TOKEN_COMPANY,
  GET_TOKEN_COMPANY_SUCCESS,
  GET_TOKEN_COMPANY_ERROR
)<GetTokenParam, GetTokenCompanyResult, AxiosError>();

export const GET_BILLING_NAME_LIST = "admin/GET_BILLING_NAME_LIST";
export const GET_BILLING_NAME_LIST_SUCCESS =
  "admin/GET_BILLING_NAME_LIST_SUCCESS";
export const GET_BILLING_NAME_LIST_ERROR = "admin/GET_BILLING_NAME_LIST_ERROR";

export const getBillingNameListAsync = createAsyncAction(
  GET_BILLING_NAME_LIST,
  GET_BILLING_NAME_LIST_SUCCESS,
  GET_BILLING_NAME_LIST_ERROR
)<GetBillingNameList, GetBillingNameListResult, AxiosError>();

export const setBillingSearchInputs = createAction(
  "admin/SET_BILLING_SEARCH_INPUTS"
)<BillingSearchInputsPayload>();

export const resetBillingSearchInputs = createAction(
  "admin/RESET_BILLING_SEARCH_INPUTS"
)();
