import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../../features/auth";
import { ClipLoader } from "react-spinners";
import "./buttons.scss";
import { memo } from "react";

type Props = {
  onClickHandler: () => void;
  btnName: string;
  loading?: boolean;
};

const MainColorBtn: React.FC<Props> = ({
  onClickHandler,
  btnName,
  loading,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <button
      className={`main_color_btn main_color_btn_${teamsTheme} ${
        loading && "disabled"
      }`}
      onClick={onClickHandler}
      disabled={loading}
    >
      <span>{btnName}</span>
      {loading && (
        <span className="loader_icon">
          <ClipLoader loading={loading} size={10} color="white" />
        </span>
      )}
    </button>
  );
};

export default memo(MainColorBtn);
