import { useEffect, useState } from "react";
import Pagenator from "../../components/common/Pagenator";
import "./admin.scss";
import { useAppDispatch } from "../../../features";
import DeleteModal from "../../components/common/DeleteModal";
import {
  ModelInfo,
  deleteModelAsync,
  getModelAsync,
  getModelsInfoAsync,
  selectModelData,
  selectModelPageIndex,
  selectModelTotalCnt,
} from "../../../features/model";
import { useSelector } from "react-redux";
import { transformDate } from "../../../libs/transformDate";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import FilterInputTextType from "../../components/userInterface/filters/FilterInputTextType";
import MobileGridBox from "../../components/mobile/admin/MobileGridBox";
import RegisteredEndpointTable from "../../components/admin/endpoint/RegisteredEndpointTable";
import EndpointManagementModal from "../../components/admin/endpoint/modal/EndpointManagementModal";

export type ModalTitleTypes = "Register" | "Modify";
export const ENDPOINT_DEFAULT_PAGE_SIZE = 10;

const ModelDevelopmentMgmtPage = () => {
  const dispatch = useAppDispatch();
  const modelData = useSelector(selectModelData);
  const totalCount = useSelector(selectModelTotalCnt);
  const modelPageIndex = useSelector(selectModelPageIndex);
  const teamsTheme = useSelector(selectTeamsTheme);

  const [pageIndex, setPageIndex] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState<ModelInfo | null>(null);
  const [searchText, setSearchText] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pageSize, setPageSize] = useState(
    window.innerWidth <= 1024 ? 6 : ENDPOINT_DEFAULT_PAGE_SIZE
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const getAdaminUserData = (idx?: number, deleteSearch?: boolean) => {
    dispatch(
      getModelAsync.request({
        pageIndex: idx ? idx : pageIndex,
        pageSize: pageSize,
        searchText: deleteSearch ? "" : searchText,
      })
    );
    dispatch(getModelsInfoAsync.request({}));

    if (deleteSearch) {
      setSearchText("");
    }
  };

  useEffect(() => {
    getAdaminUserData();

    if (window.innerWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(ENDPOINT_DEFAULT_PAGE_SIZE);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setCurrentPage = (currentPage: number) => {
    setPageIndex(currentPage);
    getAdaminUserData(currentPage);
  };

  useEffect(() => {
    getAdaminUserData();
  }, [pageSize]);

  useEffect(() => {
    setPageIndex(modelPageIndex);
  }, [modelPageIndex]);

  useEffect(() => {
    if (windowWidth <= 1024) {
      setPageSize(6);
    } else {
      setPageSize(ENDPOINT_DEFAULT_PAGE_SIZE);
    }
  }, [windowWidth]);

  const deleteHandler = () => {
    if (selectedData && selectedData._id) {
      dispatch(
        deleteModelAsync.request({
          deleteData: {
            _id: selectedData._id,
          },
          getData: {
            dataListLength: modelData.length,
            pageIndex: pageIndex,
            searchText: searchText,
            pageSize,
          },
        })
      );

      setDeleteModal(false);
    }
  };

  return (
    <>
      {addModal && (
        <EndpointManagementModal
          onClose={() => setAddModal(false)}
          title="Register"
        />
      )}
      {modifyModal && selectedData && (
        <EndpointManagementModal
          onClose={() => setModifyModal(false)}
          modifyData={selectedData}
          title="Modify"
        />
      )}
      {deleteModal && selectedData && (
        <DeleteModal
          deleteHandler={deleteHandler}
          onClose={() => setDeleteModal(false)}
        />
      )}
      <div className={`mgmt_page scroll_none`}>
        <hgroup>
          <h3 className={`page_title page_title_${teamsTheme}`}>
            Endpoint Management
          </h3>

          <MainColorBtn
            onClickHandler={() => setAddModal(true)}
            btnName="Register"
          />
        </hgroup>

        <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
          <FilterInputTextType
            title="Search"
            value={searchText}
            onChangeHandler={(e) => setSearchText(e.target.value)}
            deleteInputHandler={() => setSearchText("")}
          />

          <div className="filter_tool_btns">
            <MainColorBtn
              btnName="Search"
              onClickHandler={() => getAdaminUserData(1)}
            />
          </div>
        </div>

        <RegisteredEndpointTable
          setModifyModal={setModifyModal}
          setSelectedData={setSelectedData}
          setDeleteModal={setDeleteModal}
        />

        {/* <div className="mobile_grid_box_wrap">
          {modelData.map((model) => (
            <MobileGridBox
              data={{
                "Model Name": model.modelName,
                "Model Version": model.modelVersion,
                "Deployment Name": model.deploymentName,
                Endpoint: model.endpoint,
                Tags: model.tags,
                CreateDate: model.createDate
                  ? transformDate(model.createDate)
                  : "-",
                CreateBy: model.createBy,
              }}
              modifyHandler={() => {
                setModifyModal(true);
                setSelectedData(model);
              }}
              deleteHandelr={() => {
                setDeleteModal(true);
                setSelectedData(model);
              }}
            />
          ))}
        </div> */}

        <Pagenator
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={pageIndex}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default ModelDevelopmentMgmtPage;
