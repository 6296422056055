import AccessMgmtRoute from "../pages/pages/admin/AccessMgmtRoute";
import AdminMgmtPage from "../pages/pages/admin/AdminMgmtPage";
// import BillingMgmtPage from "../pages/pages/admin/BillingMgmtPage";
import LogMgmtRoute from "../pages/pages/admin/LogMgmtRoute";
import ModelDevelopmentMgmtPage from "../pages/pages/admin/ModelDevelopmentMgmtPage";
import Chat from "../pages/pages/chat/Chat";
import { ReactElement } from "react";
import MyAIChatMgmtPage from "../pages/pages/myAIChats/MyAIChatMgmtPage";
import MyAIChatsMgmgRoute from "../pages/pages/myAIChats/MyAIChatsMgmgRoute";
import BillingMgmtRoute from "../pages/pages/admin/BillingMgmtRoute";

export type Routes = {
  title: string;
  element: ReactElement;
  path: string;
};

type Page = (typeof pages)[number];

export const pages = [
  "CHAT",
  "ADMIN_MGMT",
  "ACCESS_MGMT",
  "LOG_MGMT",
  "BILLING_MGMT",
  "ENDPOINT_MGMT",
];

const ADMIN = "admin";
const PRIVATE = "private";

export const allRoutes: Record<Page, Routes> = {
  CHAT: {
    title: "채팅",
    element: <Chat />,
    path: `/${PRIVATE}/chat`,
  },
  MY_CHAT: {
    title: "My AI Chats",
    element: <MyAIChatsMgmgRoute />,
    path: `/${PRIVATE}/my_chat_mgmt/*`,
  },
  ADMIN_MGMT: {
    title: "관리자 관리",
    element: <AdminMgmtPage />,
    path: `/${PRIVATE}/${ADMIN}/admin_mgmt`,
  },
  ACCESS_MGMT: {
    title: "액세스 관리",
    element: <AccessMgmtRoute />,
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/*`,
  },
  LOG_MGMT: {
    title: "로그 관리",
    element: <LogMgmtRoute />,
    path: `/${PRIVATE}/${ADMIN}/log_mgmt/*`,
  },
  BILLING_MGMT: {
    title: "빌링 관리",
    element: <BillingMgmtRoute />,
    path: `/${PRIVATE}/${ADMIN}/billing_mgmt/:title`,
  },
  ENDPOINT_MGMT: {
    title: "엔드포인트 관리",
    element: <ModelDevelopmentMgmtPage />,
    path: `/${PRIVATE}/${ADMIN}/model_development_mgmt`,
  },
};

type InnerPage = (typeof innerPages)[number];

export const innerPages = [
  "ACCESS_MGMT_COMPANY",
  "ACCESS_MGMT_DEPARTMENT",
  "ACCESS_MGMT_USER",
  "BILLING_MGMT_COMPANY",
  "BILLING_MGMT_DEPARTMENT",
  "BILLING_MGMT_USER",
];

export type InnerRoutes = {
  title: string;
  path: string;
};

export const innerRoutes: Record<InnerPage, InnerRoutes> = {
  ACCESS_MGMT_COMPANY: {
    title: "회사 액세스 관리",
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/company`,
  },
  ACCESS_MGMT_DEPARTMENT: {
    title: "부서 액세스 관리",
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/dept`,
  },
  ACCESS_MGMT_USER: {
    title: "사용자 액세스 관리",
    path: `/${PRIVATE}/${ADMIN}/access_mgmt/user`,
  },
  BILLING_MGMT_COMPANY: {
    title: "회사 빌링 관리",
    path: `/${PRIVATE}/${ADMIN}/billing_mgmt/company`,
  },
  BILLING_MGMT_DEPARTMENT: {
    title: "부서 빌링 관리",
    path: `/${PRIVATE}/${ADMIN}/billing_mgmt/department`,
  },
  BILLING_MGMT_USER: {
    title: "사용자 빌링 관리",
    path: `/${PRIVATE}/${ADMIN}/billing_mgmt/user`,
  },
  LOG_MGMT_QNA: {
    title: "Q&A 로그 관리",
    path: `/${PRIVATE}/${ADMIN}/log_mgmt/log`,
  },
  LOG_MGMT_EVENT: {
    title: "이벤트 로그 관리",
    path: `/${PRIVATE}/${ADMIN}/log_mgmt/event_log`,
  },
  MY_CHAT: {
    title: "My AI Chats",
    path: `/${PRIVATE}/my_chat_mgmt/list`,
  },
  CREATE_MY_CHAT: {
    title: "My AI Chat 만들기",
    path: `/${PRIVATE}/my_chat_mgmt/create`,
  },
  EDIT_MY_CHAT: {
    title: "My AI Chat 수정",
    path: `/${PRIVATE}/my_chat_mgmt/edit`,
  },
};
