import "./NavBar.scss";
import LOGO from "../../../assets/img/logo.png";
import LOGO_WHITE from "../../../assets/img/logo_white.png";
import {
  selectIsAdmin,
  selectIsLogin,
  selectIsShowSidebar,
  selectTeamsTheme,
  selectUserName,
  setIsShowSidebar,
} from "../../../features/auth";
import { useSelector } from "react-redux";
import { BiMenu } from "react-icons/bi";
import LogBar from "../sidebar/mobile/LogBar";
import { useState } from "react";
import NewChatBtn from "../sidebar/NewChatBtn";
import { AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import SettingsModal from "../admin/SettingsModal";
import { allRoutes, innerRoutes } from "../../../routes/routes";
import {
  clearChat,
  selectChatLoading,
  setAssistantTypeInfo,
  setIsShowDeleteCheckbox,
  setModelEngine,
} from "../../../features/chat";
import { useAppDispatch } from "../../../features";
// import { setSideBarMyGPTId } from "../../../features/myGpts";
import { GPT4_CAPABILITIES } from "../../../constants/chat";
import { openAlert } from "../../../features/alert";

const NavBar = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const userName = useSelector(selectUserName);
  const isLogin = useSelector(selectIsLogin);
  const teamsTheme = useSelector(selectTeamsTheme);
  const loading = useSelector(selectChatLoading);
  const navigate = useNavigate();
  const isShowSidebar = useSelector(selectIsShowSidebar);
  const [openLog, setOpenLog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const newChatHandler = () => {
    if (loading) {
      dispatch(
        openAlert({
          alertMsg: "You cannot select this menu while it is loading.",
        })
      );
      return;
    }
    if (!loading) {
      dispatch(clearChat({ clear: true }));
      dispatch(setModelEngine("4.0v"));
      // dispatch(setSideBarMyGPTId({ id: "" }));
      // pageChangeDeleteMyChatId
      dispatch(
        setAssistantTypeInfo({
          assistantType: "DEFAULT",
          capabilities: GPT4_CAPABILITIES,
        })
      );

      navigate("/private/chat");
    }
  };

  const closeMobileSidebar = () => {
    setOpenLog(false);
    dispatch(setIsShowDeleteCheckbox(false));
  };
  return (
    <>
      {openModal && <SettingsModal onClose={() => setOpenModal(false)} />}
      <nav className={`NavBar ${teamsTheme}`}>
        <div className={`logo_wrap`}>
          {teamsTheme === "default" ? (
            <img
              className="logo"
              onClick={newChatHandler}
              src={LOGO}
              alt="logo"
            />
          ) : (
            <img
              className="logo"
              onClick={newChatHandler}
              src={LOGO_WHITE}
              alt="logo"
            />
          )}

          {isLogin && (
            <div className="userInfo_logout_wrap">
              <div className="user_info">
                <AiOutlineUser
                  className={`user_icon user_icon_${teamsTheme}`}
                />

                <span className={`username username_${teamsTheme}`}>
                  {userName}
                </span>
              </div>

              <div className="setting_wrap">
                <button className={`setting ${teamsTheme}`}>
                  <AiOutlineSetting />
                </button>

                <div className={`setting_menu setting_menu_${teamsTheme}`}>
                  {isAdmin && (
                    <>
                      <button>
                        <Link to={allRoutes.ADMIN_MGMT.path}>
                          Admin Management
                        </Link>
                      </button>
                      {window.innerWidth > 479 && (
                        <button className="disApear_link">
                          <Link to={innerRoutes.LOG_MGMT_QNA.path}>
                            Log Management
                          </Link>
                        </button>
                      )}
                      <button>
                        <Link to={innerRoutes.ACCESS_MGMT_COMPANY.path}>
                          Access Rights Management
                        </Link>
                      </button>
                      {window.innerWidth > 479 && (
                        <button className="disApear_link">
                          <Link to={allRoutes.ENDPOINT_MGMT.path}>
                            Endpoint Management
                          </Link>
                        </button>
                      )}

                      {window.innerWidth > 479 && (
                        <button className="disApear_link">
                          <Link to={innerRoutes.BILLING_MGMT_COMPANY.path}>
                            Billing Management
                          </Link>
                        </button>
                      )}
                    </>
                  )}

                  <button className="disApear_link">
                    <Link to={innerRoutes.MY_CHAT.path}>
                      {innerRoutes.MY_CHAT.title}
                    </Link>
                  </button>

                  <button onClick={() => setOpenModal(true)}>Settings</button>
                </div>
              </div>
            </div>
          )}
        </div>

        {isLogin && (
          <>
            <div className={`new_chat_wrap new_chat_wrap_${teamsTheme}`}>
              <button
                onClick={() => dispatch(setIsShowSidebar(!isShowSidebar))}
                className={`desktop_hamburger_wrap ${teamsTheme}`}
              >
                <BiMenu />
              </button>
              <NewChatBtn />
            </div>
            <div className="hamburger_wrap">
              <button
                onClick={() => setOpenLog(true)}
                className={`new_chat_wrap_hambg new_chat_wrap_hambg_${teamsTheme}`}
              >
                <BiMenu />
              </button>
              {openLog && <LogBar onClose={closeMobileSidebar} />}
            </div>
          </>
        )}
      </nav>
    </>
  );
};

export default NavBar;
