import axios from "axios";

import {
  EventStreamContentType,
  fetchEventSource,
} from "@microsoft/fetch-event-source";
import { getAuthTokenHandler } from "../../apis/invalidToken";
import { errHandler } from "../../libs/errHandler";
import { AssistantType, FileCard, LocalUploadFile, ModelEngine } from "./types";

export interface IFileInfoItem {
  fileName: string;
  fileId: string;
  // createdTime: string;
  // isVectorSupported: boolean;
}
export interface IFileUploadResponse {
  fileInfo: IFileInfoItem;
  listId: string;
}

export interface MessageInfo {
  _id: string;
  listId: string;
  question: string;
  keyword: string;
  answer: string;
  source: MessageSourceInfo[];
  createdBy: string;
  createdDate: string;
  updatedDate: string;
  failure: boolean;
  bingSearch: boolean;
  favor: -1 | 0 | 1;
  latency: number;
  finishReason: "stop" | "length";
  answerDone?: boolean;
  fileCards?: FileCard[];
  fileName?: string;
  fileId?: string;
  fileInfo: IFileInfoItem[];
  dalleUrl: string;
  isPreviewChat?: boolean;
}

export interface MessageSourceInfo {
  bingType: string;
  bingSourceUrl: string;
  bingSourceName: string;
  bingSourceContext: string;
  bingSourceProvider: string;
}

export interface GetAnswerParam {
  messageInfo: GetAnswerMessageInfo;
}

export interface ICAssistantParam {
  listId?: string | null;
  prompt: string;
  temperature: number;
  fileNames?: string[];
  fileCards?: FileCard[];
  fileInfo?: IFileInfoItem[];
  // myGPTsId?: string;
}
export interface IDalleParam extends ICAssistantParam {
  genImgNum: number;
}

export interface RegenerateAnswerParam {
  messageInfo: GetAnswerMessageInfo;
  itemId: string;
}

// export interface IRegenerateDalleParam extends IDalleParam {
//   itemId: string;
// }
export interface IAssistantParam extends ICAssistantParam {
  gptVersion?: string;
  myGPTsId?: string | null;
  assistantType?: AssistantType;
  isPreviewChat?: boolean;
}

export interface IRegenerateParam extends IAssistantParam {
  genImgNum?: number;
  itemId: string;
}

export interface IAssistantDalleParam extends ICAssistantParam {
  gptVersion: null;
  myGPTsId: null;
  assistantType: "DALLE";
  isPreviewChat?: false;
}

export interface GetAnswerMessageInfo {
  userId: string;
  listId?: string;
  prompt: string;
  prevMessages: MessageInfo[];
  bingSearch: boolean;
  temperature: number;
  model: string;
}

export interface GetAnswerResult {
  message: MessageInfo;
  cancelAPI: any;
}

export interface GetBeforeChatListParam {
  userId: string;
  pageIndex: number;
  pageSize: number;
  searchText: string;
}

export interface GetBeforeChatListResult {
  pinned: BeforeChatData[];
  result: BeforeChatData[];
  pageSize: number;
}

export interface BeforeChatData {
  _id: string;
  title: string;
  description: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  pinned: boolean;
  chatType: string | null;
  fileInfo: IFileInfoItem[];
  assistantCapabilities?: null | AssistantType[];
  qaModelEngine: ModelEngine;
}

export interface DeleteChatParam {
  listIds: string[];
  // userId: string;
  // chat: BeforeChatData;
}

export interface GetSelectChatInfoParam {
  listId: string;
  userId: string;
}

export interface IGPTAssistantInfo {
  myGPTsId: string | null;
  myGPTsName: string | null;
  profileImage: string | null;
  lastQAType: AssistantType | null;
  assistantCapabilities: AssistantType[];
  qaModelEngine: ModelEngine;
}
export interface GetSelectChatInfoResult {
  id: string;
  messageList: MessageInfo[];
  gptAssistantInfo?: IGPTAssistantInfo | null;
}

export interface ModifyChatParam {
  listId: string;
  title: string;
  pinned: boolean;
}

export interface ModifyChatResult extends BeforeChatData {
  deleteFlag: boolean;
}

export interface LikeAnswerParam {
  itemId: string;
  favor: -1 | 0 | 1;
}

export interface DispatchHandlerType {
  streamUpdate: (answer: string) => void;
}

export interface IUploadFileParam {
  fileList: IUploadFileInfo[];
}

export interface IUploadFileInfo {
  param: FormData;
  uploadFileData: LocalUploadFile;
}

export interface IUploadFile {
  fileId: string;
  listId?: string;
}
export const uploadFile = async (
  param: FormData
): Promise<IFileUploadResponse> => {
  const res = await axios.post("/assistant/uploadFile", param, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export interface IDeleteFileResponse {
  fileId: string;
  result: boolean;
}
export const deleteFile = async (
  fileId: string
): Promise<IDeleteFileResponse> => {
  const res = await axios.post("/assistant/removeFile", { fileId });

  return { result: res.data, fileId };
};
export const getAnswer = async (
  param: GetAnswerParam,
  cancelToken: any
): Promise<MessageInfo> => {
  const res = await axios.post("/gpt/gpt3", param, cancelToken);

  return res.data.message;
};

export const getBeforeChatList = async (
  param: GetBeforeChatListParam
): Promise<GetBeforeChatListResult> => {
  const res = await axios.post("/qalist/get_qa_list", param);

  return res.data;
};

export const deleteCheckedChats = async (
  param: DeleteChatParam
): Promise<DeleteChatParam> => {
  await axios.post("/qalist/remove_qa_list", { listIds: param.listIds });

  return param;
};

// export const deleteAllChats = async (
//   param: DeleteChatParam
// ): Promise<DeleteChatParam> => {
//   await axios.post("/qalist/remove_all_qa_list", param);

//   return param;
// };

export const getSelectChatInfo = async (
  param: GetSelectChatInfoParam
): Promise<GetSelectChatInfoResult> => {
  const res = await axios.post("/qalist/get_qa_items", param);

  const newMassageList = res.data.result.map((item: MessageInfo) => {
    if (item.failure && item.answer.includes("Error code")) {
      return {
        ...item,
        answer: "GPT Model has failed to generate the answer.",
      };
    }
    return item;
  });

  return {
    messageList: newMassageList,
    id: param.listId,
    gptAssistantInfo: res.data.gptAssistantInfo,
  };
};

export const modifyChat = async (
  param: ModifyChatParam
): Promise<ModifyChatResult> => {
  const res = await axios.put("/qalist/qa_list", param);

  return res.data;
};

export const likeAnswer = async (
  param: LikeAnswerParam
): Promise<MessageInfo> => {
  const res = await axios.put("/qaitem/qa_item", param);

  return res.data;
};

export const deleteItem = async (id: string): Promise<MessageInfo> => {
  const res = await axios.delete(`/qaitem/qa_item/${id}`);

  return res.data;
};

export const getStreamAnswer = async (
  param: IAssistantParam,
  dispatchHandler: DispatchHandlerType,
  signal: any
) => {
  let streamDoneObj: any;
  let answer = "";

  const currentToken = await getAuthTokenHandler();

  await fetchEventSource(
    `${process.env.REACT_APP_SERVICE_URL}/gpt/gpt_stream`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: "Bearer " + currentToken,
      },
      body: JSON.stringify(param),
      signal: signal,
      openWhenHidden: true,
      keepalive: true,
      redirect: "error",
      async onopen(response) {
        if (
          response.ok &&
          response.headers.get("content-type") === EventStreamContentType
        ) {
          console.log("everything's good");
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429
        ) {
          // client-side errors are usually non-retriable:
          console.log("opnopenError_1", response);
          errHandler(response.status);
        } else {
          console.log("opnopenError_2", response);
          errHandler(response.status);
        }
      },
      onmessage(e) {
        if (e.data.includes(`"streamDone" : `)) {
          const [emptyString, streamDoneJson] = e.data.split(`"streamDone" : `);
          streamDoneObj = JSON.parse(streamDoneJson);
          dispatchHandler.streamUpdate("");
          return;
        }

        const removedDoubleQuotation = e.data.replace(/^"|"$/g, "");
        const removedNewLine = removedDoubleQuotation
          .replace(/\\n\\n/g, "\\u0001") // 연속된 개행 문자를 임시 문자로 변환
          .replace(/\\n(?![a-zA-Z])/g, "\n") // LaTeX 문법을 제외한 개행 문자 변환
          .replace(/\\u0001/g, "\n\n") // 임시 문자를 다시 연속된 개행 문자로 변환
          .replace(/\\\\/g, "\\"); // \\을 \로 변환

        if (e.data) {
          answer += removedNewLine;
          dispatchHandler.streamUpdate(answer);
        }
      },
      onclose() {
        console.log("onclose");
      },
      onerror(err) {
        if (err) {
          console.log("onerror", err);
          throw err; // rethrow to stop the operation
        } else {
          // do nothing to automatically retry. You can also
          // return a specific retry interval here.
        }
      },
    }
  );
  console.log("Stream Done");
  if (streamDoneObj) return streamDoneObj;
};
