import { RootState } from "../index";

export const selectLogData = (state: RootState) => state.log.logData;

export const selectLogTotalCnt = (state: RootState) => state.log.logTotalCount;

export const selectEventLogData = (state: RootState) => state.log.eventLogData;

export const selectEventLogTotalCnt = (state: RootState) =>
  state.log.eventLogTotalCount;

export const selectTotalTokenData = (state: RootState) =>
  state.log.totalTokenData;
export const selectTokenLogData = (state: RootState) =>
  state.log.tokenLogDataList;
export const selectTokenCompanyData = (state: RootState) =>
  state.log.tokenCompanyData;

export const selectBillingNameList = (state: RootState) =>
  state.log.billingNameList;

export const selectBillingSearchInputs = (state: RootState) =>
  state.log.billingSearchInputs;
