import "./Chat.scss";
import { useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  clearChat,
  selectChatList,
  getAnswerAsync,
  selectChatLoading,
  regenerateAnswerAsync,
  selectFirstSelectedChatId,
  selectModelEngine,
  getDalleAnswerAsync,
  setPromptText,
  selectPromptText,
  selectFileCards,
  selectQaListId,
  setInputUploadFiles,
  selectFileInfoList,
  selectAssistantTypeInfo,
  setModelEngine,
  setIsScrollToBottom,
  selectIsScrollToBottom,
} from "../../../features/chat";
import {
  selectTeamsTheme,
  selectTemperature,
  selectIsContractAgreed,
} from "../../../features/auth";
import CHAT from "../../../constants/chat";
import { useAppDispatch } from "../../../features";
import ChatContent from "../../components/chat/mainChat/ChatContent";
import InitialConsentPopup from "../../components/common/InitialConsentPopup";
import ChatControlButton from "../../components/chat/mainChat/ChatControlButton";
import ScrollButton from "../../components/chat/mainChat/ScrollButton";
import ChatIntro from "../../components/chat/ChatIntro";
import ChatInputForm from "../../components/chat/mainChat/ChatInputForm";
import { ConversationStarterList } from "../../components/chat/recommendedPrompt/ConversationStarterList";
import { SelectGPTModel } from "../../components/chat/mainChat/SelectGPTModel";
import clsx from "clsx";
import { DalleStylePromptList } from "../../components/chat/recommendedPrompt/DalleStylePromptList";
import useWindowResize from "../../../libs/hooks/useWindowResize";
import SelectSearchTypeList from "../../components/chat/SelectSearchTypeList";
import MyAIChatIntro from "../../components/chat/MyAIChatIntro";
import useResponsiveView from "../../../libs/hooks/useResonsiveView";
import { AssistantType } from "../../../features/chat/types";
import { selectQAItemLoading } from "../../../features/ui_loading";
const { SUPPORTED_BY_DOOSAN, EMPTY_CHAT_INDEX } = CHAT;
const removeSupportedText = (content: string) =>
  content.replace(SUPPORTED_BY_DOOSAN, "");

const Chat = () => {
  const { isMobile, isTablet, isDesktop } = useResponsiveView();
  const windowSize = useWindowResize();
  const dispatch = useAppDispatch();
  const promptText = useSelector(selectPromptText);
  const teamsTheme = useSelector(selectTeamsTheme);
  const qaListId = useSelector(selectQaListId);
  const firstChatId = useSelector(selectFirstSelectedChatId);
  const chatList = useSelector(selectChatList);
  const fileCards = useSelector(selectFileCards);
  const isContractAgreed = useSelector(selectIsContractAgreed);
  const fileInfoList = useSelector(selectFileInfoList);
  const modelEngine = useSelector(selectModelEngine);
  const assistantTypeInfo = useSelector(selectAssistantTypeInfo);
  const temperature = useSelector(selectTemperature);
  const chatLoading = useSelector(selectChatLoading);
  const qaItemLoading = useSelector(selectQAItemLoading);
  const isScrollToBottom = useSelector(selectIsScrollToBottom);

  const chatTextContainerRef = useRef<HTMLDivElement>(null);
  const chatIntroContainerRef = useRef<HTMLDivElement>(null);
  const chatContentRef = useRef<HTMLDivElement>(null);

  const [scrollDown, setScrollDown] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearChat({ clear: true }));
      dispatch(setModelEngine("4.0v"));
      dispatch(setIsScrollToBottom(true));
    };
  }, []);

  useLayoutEffect(() => {
    if (!chatTextContainerRef?.current || !chatIntroContainerRef?.current)
      return;

    chatIntroContainerRef.current.style.top = "30%"; // FIXED VALUE
    const textareaHeight = chatTextContainerRef.current.offsetHeight;
    const introHeight = chatIntroContainerRef.current.offsetHeight;
    const introTop = chatIntroContainerRef.current.offsetTop;

    if (isDesktop) {
      const flagNum = introHeight / 2 + 94; // FIXED VALUE
      if (textareaHeight > flagNum) {
        const top = introTop + (flagNum - textareaHeight);
        chatIntroContainerRef.current.style.top = `${top}px`;
        return;
      }
    }
    if (isMobile || isTablet) {
      const isInitTabMenu =
        textareaHeight === 0 && introHeight === 0 && introTop === 0;
      if (isInitTabMenu) return;

      const tempNum = isMobile ? 240 : 400; // FIXED VALUE
      const flagNum = introHeight / 2 + tempNum; // FIXED VALUE

      if (textareaHeight > flagNum) {
        const top = introTop + (flagNum - textareaHeight);
        chatIntroContainerRef.current.style.top = `${top}px`;
        return;
      }
    }
  }, [promptText, fileCards, windowSize]);

  useEffect(() => {
    setScrollDown(false);
  }, [qaListId]);

  const requestRegenerateChat = (isInitChat: boolean, listId: string) => {
    const lastChat = chatList[chatList.length - 1];

    if (modelEngine === "dalle") {
      const value: AssistantType = "DALLE";
      const chatPayload = {
        prompt: removeSupportedText(lastChat.question),
        gptVersion: "4.0v",
        temperature,
        itemId: lastChat._id,
        fileInfo: [],
        assistantType: value,
        listId: isInitChat ? null : listId,
        myGPTsId: assistantTypeInfo.myGPTsId ?? null,
      };

      if (isInitChat) {
        dispatch(regenerateAnswerAsync.request(chatPayload));
        return;
      }
      dispatch(
        regenerateAnswerAsync.request({
          ...chatPayload,
          listId,
        })
      );
      return;
    }

    const regeneratePayload = {
      prompt: removeSupportedText(lastChat.question),
      temperature,
      itemId: lastChat._id,
      fileInfo: fileInfoList,
      assistantType: assistantTypeInfo.assistantType,
    };

    if (modelEngine === "3.5v" || modelEngine === "4.0v") {
      Object.assign(regeneratePayload, { gptVersion: modelEngine });
    }
    if (modelEngine === "myGPTs") {
      Object.assign(regeneratePayload, {
        myGPTsId: assistantTypeInfo.myGPTsId ?? null,
      });
    }

    dispatch(
      regenerateAnswerAsync.request({
        ...regeneratePayload,
        listId: isInitChat ? null : listId,
        itemId: regeneratePayload.itemId,
      })
    );
  };

  const submitChatHandler = (isRegenerate?: boolean) => {
    // 채팅 버전에 맞춰진 토큰개수가 초과되면 early return
    // if (getMaxTokenNumberByVersion(modelEngine) < tokenCount) return;

    if (chatLoading) return;
    dispatch(setIsScrollToBottom(true));

    // input창의 질문을 삭제하고, 채팅 타입을 3.5v로 변경
    dispatch(setPromptText(""));
    dispatch(setInputUploadFiles(null));

    if (isRegenerate) {
      if (modelEngine === "dalle") {
        postDalleChat(isRegenerate);
      }
      postGPTChat(isRegenerate);
      return;
    }

    // 내용이 없을 경우 return
    if (promptText.replace(/\s/gi, "").length === 0) {
      return;
    }

    if (modelEngine === "dalle") {
      postDalleChat();
      return;
    }
    postGPTChat();
  };

  const postDalleChat = (isRegenerate?: boolean) => {
    const isInitChat =
      firstChatId === EMPTY_CHAT_INDEX && qaListId === EMPTY_CHAT_INDEX;
    const existedChatId =
      qaListId === EMPTY_CHAT_INDEX ? firstChatId : qaListId;

    if (isRegenerate) {
      requestRegenerateChat(isInitChat, existedChatId);
      return;
    }

    const value: AssistantType = "DALLE";

    const chatPayload = {
      prompt: removeSupportedText(promptText),
      gptVersion: null,
      temperature,
      listId: existedChatId === EMPTY_CHAT_INDEX ? null : existedChatId,
      fileInfo: fileInfoList,
      assistantType: value,
      myGPTsId: null,
    };

    if (isInitChat) {
      dispatch(getDalleAnswerAsync.request(chatPayload));
      return;
    }

    dispatch(
      getDalleAnswerAsync.request({
        ...chatPayload,
        listId: existedChatId,
      })
    );
  };

  const postGPTChat = (isRegenerate?: boolean) => {
    const isInitChat =
      firstChatId === EMPTY_CHAT_INDEX && qaListId === EMPTY_CHAT_INDEX;
    const existedChatId =
      qaListId === EMPTY_CHAT_INDEX ? firstChatId : qaListId;

    if (isRegenerate) {
      requestRegenerateChat(isInitChat, existedChatId);
      return;
    }
    const chatPayload = {
      prompt: removeSupportedText(promptText),
      gptVersion: modelEngine,
      temperature,
      listId: existedChatId === EMPTY_CHAT_INDEX ? null : existedChatId,
      fileInfo: fileInfoList,
      assistantType: assistantTypeInfo.assistantType,
      myGPTsId: assistantTypeInfo.myGPTsId ?? null,
    };

    if (isInitChat) {
      dispatch(getAnswerAsync.request(chatPayload));
      return;
    }

    dispatch(
      getAnswerAsync.request({
        ...chatPayload,
        listId: existedChatId,
      })
    );
  };

  const handleScroll = () => {
    const scrollBody = document.getElementById("chat_body");

    const scrollTop = scrollBody?.scrollTop;
    const scrollHeight = scrollBody?.scrollHeight;
    const clientHeight = scrollBody?.clientHeight;

    if (scrollTop && scrollHeight && clientHeight) {
      if (scrollTop + clientHeight <= scrollHeight - 100) {
        setScrollDown(true);
      } else {
        setScrollDown(false);
      }
    }
  };

  useEffect(() => {
    const updateScrollHeight = () => {
      if (chatContentRef.current && isScrollToBottom) {
        // 데이터를 모두 불러온 후 scrollHeight를 가져옴
        const scrollHeight = chatContentRef.current.scrollHeight;
        chatContentRef.current.scrollTo(0, scrollHeight);

        // if(chatLoading)
        if (!chatLoading) dispatch(setIsScrollToBottom(false));
      }
    };

    // 데이터를 비동기로 모두 불러온 후에 scrollHeight를 업데이트
    const timer = setTimeout(updateScrollHeight, 0);

    // 클린업 함수로 타이머 제거
    return () => clearTimeout(timer);
  }, [chatList, isScrollToBottom, chatLoading]);

  return (
    <>
      {!isContractAgreed && <InitialConsentPopup />}
      <div className="chat-page_container">
        {!qaItemLoading && (
          <>
            {chatList.length === 0 && !assistantTypeInfo.myGPTsId && (
              <ChatIntro ref={chatIntroContainerRef} />
            )}
            {chatList.length === 0 && assistantTypeInfo.myGPTsId && (
              <MyAIChatIntro ref={chatIntroContainerRef} />
            )}
          </>
        )}

        <div className="chat_reading_container scroll_none">
          <div
            className={`chat_reading_view scroll scroll_${teamsTheme}`}
            onScroll={handleScroll}
            id="chat_body"
            ref={chatContentRef}
          >
            <ChatContent />
          </div>
        </div>

        <div className="chat_writing_container" ref={chatTextContainerRef}>
          <div className="chat_writing_view">
            <ChatControlButton addChatHandler={submitChatHandler} />
            {((!qaItemLoading && modelEngine === "4.0v") ||
              modelEngine === "3.5v") &&
              chatList.length === 0 && <ConversationStarterList />}
            {modelEngine === "dalle" && (
              <DalleStylePromptList windowSize={windowSize} />
            )}
            {scrollDown && chatList.length > 0 && <ScrollButton />}
            <div
              className={clsx("toggle_margin", {
                toggle_none: modelEngine === "dalle", // FIXED VALUE
              })}
            >
              <div
                className={clsx({
                  toggle_hidden: modelEngine === "3.5v", // FIXED VALUE
                })}
              >
                <SelectSearchTypeList />
              </div>
              {(modelEngine === "3.5v" || modelEngine === "4.0v") && (
                <SelectGPTModel />
              )}
            </div>
            <ChatInputForm addChatHandler={submitChatHandler} />
            {/* <TokenGuide
              currentTokenCount={tokenCount}
              chatModel={modelEngine}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
