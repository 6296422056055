import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { localDate } from "../../../../libs/transformDate";
import { selectTeamsTheme } from "../../../../features/auth";
import { TOKEN_MODEL_CODE } from "../../../../constants/tokenManagement";
import FilterPeriod from "../../../components/userInterface/filters/FilterPeriod";
import FilterSelectType from "../../../components/userInterface/filters/FilterSelectType";
import FilterInputTextInCheckLableModal from "../../../components/userInterface/filters/FilterInputTextInCheckLableModal";
import {
  BillingTitle,
  getTokenLogAsync,
  selectBillingNameList,
  selectBillingSearchInputs,
  setBillingSearchInputs,
} from "../../../../features/log";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../features";
import { selectBillingNameListLoading } from "../../../../features/ui_loading";
import MainColorBtn from "../../../components/userInterface/buttons/MainColorBtn";

const models = [
  { name: "ALL", value: TOKEN_MODEL_CODE.ALL },
  { name: "Assistant", value: TOKEN_MODEL_CODE.ASSISTANT },
  { name: "My AI Chats", value: TOKEN_MODEL_CODE.MY_GPTS },
  { name: "DALLE", value: TOKEN_MODEL_CODE.DALLE },
];

export const BillingSearch = ({ title }: { title: string }) => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const billingNameList = useSelector(selectBillingNameList);
  const billingNameListLoading = useSelector(selectBillingNameListLoading);
  const billInputs = useSelector(selectBillingSearchInputs);

  const getTokenLogData = () => {
    dispatch(
      getTokenLogAsync.request({
        payload: {
          ...billInputs,
          model: Number(billInputs.model),
          filterList: billInputs.filterList.length ? billInputs.filterList : [],
          filterType: billInputs.filterType,
        },
        title: params.title as BillingTitle,
      })
    );
  };

  useEffect(() => {
    if (billInputs.filterType === undefined || billingNameListLoading) return;
    getTokenLogData();
  }, [
    billingNameListLoading,
    billingNameList,
    billInputs.filterType,
    // billInputs.filterList,
  ]);

  //! filter company 변경
  const onChangeCheckName = (item: { name: string; value: string }) => {
    const { filterList } = billInputs;
    if (filterList.includes(item.value)) {
      const newFilterList = filterList.filter((name) => name !== item.value);
      dispatch(
        setBillingSearchInputs({
          filterList: newFilterList,
        })
      );
      return;
    }
    dispatch(
      setBillingSearchInputs({
        filterList: [...filterList, item.value],
      })
    );
  };

  const onChangeAllCheckName = () => {
    const { filterList } = billInputs;

    if (filterList.length === billingNameList.length) {
      dispatch(
        setBillingSearchInputs({
          filterList: [],
        })
      );
      return;
    }
    dispatch(
      setBillingSearchInputs({
        filterList: billingNameList.map((item) => item.code),
      })
    );
  };

  return (
    <div className={`mgmt_page_filter mgmt_page_filter_${teamsTheme}`}>
      <FilterPeriod
        startDate={localDate(billInputs.startDate)}
        endDate={localDate(billInputs.endDate)}
        max={moment().format("YYYY-MM-DD")}
        startDateChangeHandler={(e) =>
          dispatch(
            setBillingSearchInputs({
              startDate: new Date(e.target.value),
            })
          )
        }
        endDateChangeHandler={(e) =>
          dispatch(
            setBillingSearchInputs({
              endDate: new Date(e.target.value),
            })
          )
        }
      />

      <FilterSelectType
        title="Model"
        value={billInputs.model.toString()}
        onChangeHandler={(e) =>
          dispatch(
            setBillingSearchInputs({
              model: e.target.value,
            })
          )
        }
        options={models.map((model) => {
          return { name: model.name, value: model.value.toString() };
        })}
      />

      <FilterInputTextInCheckLableModal
        title={title + " Name"}
        onChangeHandler={onChangeCheckName}
        allCheckHandler={onChangeAllCheckName}
        checkedData={billInputs.filterList}
        defaultData={billingNameList.map((item) => ({
          name: item.name,
          value: item.code,
        }))}
      />

      <div className="filter_tool_btns">
        <MainColorBtn
          btnName="Search"
          onClickHandler={() => getTokenLogData()}
          loading={billingNameListLoading}
        />
      </div>
    </div>
  );
};
