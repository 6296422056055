import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTeamsTheme } from "../../../features/auth";
import MainColorBtn from "../../components/userInterface/buttons/MainColorBtn";
import "./admin.scss";
import TabBtn from "../../components/userInterface/buttons/TabBtn";
import { innerRoutes } from "../../../routes/routes";
import {
  BillingTitle,
  BillingUpperTitle,
  getBillingNameListAsync,
  resetBillingSearchInputs,
  selectBillingSearchInputs,
  setBillingSearchInputs,
} from "../../../features/log";
import { openAlert } from "../../../features/alert";
import { useAppDispatch } from "../../../features";
import { BillingSearch } from "./billing/BillingSearch";
import { TokenLogTable } from "./billing/TokenLogTable";
import { selectBillingNameListLoading } from "../../../features/ui_loading";

const billingParams: BillingTitle[] = ["company", "department", "user"];

const BillingMgmtRoute = () => {
  const params = useParams();
  const navigate = useNavigate();
  const billingNameListLoading = useSelector(selectBillingNameListLoading);

  const dispatch = useAppDispatch();
  const teamsTheme = useSelector(selectTeamsTheme);
  const [selectTab, setSelectTab] = useState<BillingTitle>("company");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const billInputs = useSelector(selectBillingSearchInputs);
  const [nameTitle, setNameTitle] = useState<BillingUpperTitle>("Company");

  useEffect(() => {
    return () => {
      dispatch(resetBillingSearchInputs());
    };
  }, []);

  useEffect(() => {
    if (!params.title) return;
    if (!billingParams.includes(params.title as BillingTitle)) {
      navigate(innerRoutes.BILLING_MGMT_COMPANY.path);
      return;
    }
    const { title } = params;
    setSelectTab(title as BillingTitle);
    dispatch(resetBillingSearchInputs());

    if (params.title === "company") {
      setNameTitle("Company");
      dispatch(
        setBillingSearchInputs({
          filterType: "COMP",
        })
      );
    }
    if (params.title === "department") {
      setNameTitle("Department");
      dispatch(
        setBillingSearchInputs({
          filterType: "DEPT",
        })
      );
    }

    if (params.title === "user") {
      setNameTitle("User");
      dispatch(
        setBillingSearchInputs({
          filterType: "USER",
        })
      );
    }

    dispatch(
      getBillingNameListAsync.request({
        payload: {
          startDate: billInputs.startDate,
          endDate: billInputs.endDate,
          model: Number(billInputs.model),
        },
        title: params.title as BillingTitle,
      })
    );
  }, [params]);

  const downloadTokenHandler = async () => {
    setDownloadLoading(true);

    await axios
      .post(`/log/tokenlogDownload`, billInputs, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `billing.xlsx`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setDownloadLoading(false);
      })
      .catch((err) => {
        dispatch(
          openAlert({
            alertMsg: "failed download",
          })
        );
        setDownloadLoading(false);
      });
  };
  return (
    <>
      <div className={`mgmt_page scroll_none`}>
        <hgroup>
          <div className="title_tab_wrap">
            <h3 className={`page_title page_title_${teamsTheme}`}>
              Billing Management
            </h3>
            <div className={`tab_btn_wrap tab_btn_wrap_${teamsTheme}`}>
              <TabBtn
                tabName="Company"
                selectedTab={selectTab}
                value="company"
                onClickHandler={() => setSelectTab("company")}
                pathName={innerRoutes.BILLING_MGMT_COMPANY.path}
                loading={billingNameListLoading}
              />
              <TabBtn
                tabName="Department"
                selectedTab={selectTab}
                value="department"
                onClickHandler={() => setSelectTab("department")}
                pathName={innerRoutes.BILLING_MGMT_DEPARTMENT.path}
                loading={billingNameListLoading}
              />
              <TabBtn
                tabName="User"
                selectedTab={selectTab}
                value="user"
                onClickHandler={() => setSelectTab("user")}
                pathName={innerRoutes.BILLING_MGMT_USER.path}
                loading={billingNameListLoading}
              />
            </div>
          </div>
          <MainColorBtn
            onClickHandler={downloadTokenHandler}
            btnName="Download"
            loading={downloadLoading}
          />
        </hgroup>
        <BillingSearch title={nameTitle} />
        <TokenLogTable title={nameTitle} />
      </div>
    </>
  );
};

export default BillingMgmtRoute;
