import { createReducer } from "typesafe-actions";
import produce from "immer";
import { LogState, LogAction } from "./types";
import {
  getBillingNameListAsync,
  getEventLogDataAsync,
  getLogDataAsync,
  getTokenCompanyAsync,
  getTokenLogAsync,
  resetBillingSearchInputs,
  setBillingSearchInputs,
} from "./actions";

import moment from "moment";

const initialSearchInputs = {
  startDate: new Date(moment().subtract(1, "months").format("YYYY-MM-DD")),
  endDate: new Date(moment().format("YYYY-MM-DD")),
  model: "0",
  filterList: [],
  filterType: undefined,
};
const initialTotalTokenData = {
  totalAnswerTokens: 0,
  totalQuestionTokens: 0,
  totalTokens: 0,
};
const initialState = {
  logData: [],
  logTotalCount: 0,
  eventLogData: [],
  eventLogTotalCount: 0,
  totalTokenData: initialTotalTokenData,
  // tokenLogData: [],
  // tokenCompanyData: [],
  tokenLogDataList: [],
  tokenList: [],
  billingNameList: [],
  billingSearchInputs: initialSearchInputs,
};

const log = createReducer<LogState, LogAction>(initialState)
  .handleAction(getLogDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.logData = payload.result;
      draft.logTotalCount = payload.totalCount;
    });
  })
  .handleAction(getEventLogDataAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.eventLogData = payload.result;
      draft.eventLogTotalCount = payload.totalCount;
    });
  })
  .handleAction(getTokenLogAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.totalTokenData = {
        totalAnswerTokens: payload.totalAnswerTokens,
        totalQuestionTokens: payload.totalQuestionTokens,
        totalTokens: payload.totalTokens,
      };
      draft.tokenLogDataList = payload.result;
    });
  })
  .handleAction(getTokenCompanyAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.tokenCompanyData = payload;
    });
  })
  .handleAction(getBillingNameListAsync.success, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.billingNameList = payload;
      draft.billingSearchInputs.filterList = payload
        .filter((item) => item.hasLog)
        .map((item) => item.code);
    });
  })
  .handleAction(setBillingSearchInputs, (state, { payload }) => {
    return produce(state, (draft) => {
      draft.billingSearchInputs = { ...state.billingSearchInputs, ...payload };
    });
  })
  .handleAction(resetBillingSearchInputs, (state) => {
    return produce(state, (draft) => {
      draft.billingSearchInputs = { ...initialSearchInputs };
      draft.tokenLogDataList = [];
      draft.totalTokenData = initialTotalTokenData;
    });
  });

export default log;
