import { createReducer } from "typesafe-actions";
import { LoadingAction, LoadingState } from "./types";
import {
  errorAction,
  startAction,
  stopAction,
  successAction,
  successMessageAction,
} from "./actions";
import produce from "immer";
import { concat } from "../../libs";
import { addModelAsync, deleteModelAsync, updateModelAsync } from "../model";

function getSuccessMsg(successAction: string) {
  switch (successAction) {
    // case returnTeamVacationAsync.success.toString():
    //   return "반려 완료";
    case addModelAsync.success.toString():
      return "등록 완료";
    case updateModelAsync.success.toString():
      return "수정 완료";
    case deleteModelAsync.success.toString():
      return "삭제 완료";

    default:
      return "";
  }
}

function getErrorMsg(errorAction: string) {
  switch (errorAction) {
    // case updateRegisteredMyVacationAsync.failure.toString():
    //   return "중복된 날짜는 등록하실 수 없습니다";
    default:
      return "";
  }
}

const initialState: LoadingState = {
  actions: [],
  success: {
    msg: "",
  },
  error: {
    msg: "",
  },
};

const loading = createReducer<LoadingState, LoadingAction>(initialState)
  .handleAction(startAction, (state, action) => {
    return {
      ...state,
      actions: concat(state.actions, action.payload),
    };
  })
  .handleAction(stopAction, (state, action) => {
    return {
      ...state,
      actions: state.actions.filter((a) => a.name !== action.payload.name),
    };
  })
  .handleAction(successAction, (state, action) => {
    return produce(state, (draft) => {
      const msg = getSuccessMsg(action.payload.type);
      if (msg.length) {
        draft.success = {
          msg,
        };
      }
    });
  })
  .handleAction(successMessageAction, (state, action) => {
    return produce(state, (draft) => {
      const msg = getSuccessMsg(action.payload.type);

      if (action.payload.successMsg) {
        draft.success = {
          msg: action.payload.successMsg,
        };
      } else {
        if (msg.length) {
          draft.success = {
            msg,
          };
        }
      }
    });
  })
  .handleAction(errorAction, (state, action) => {
    return produce(state, (draft) => {
      const msg = getErrorMsg(action.payload.type);

      if (msg.length > 0) {
        draft.error = {
          msg,
        };
      } else if (action.payload.msg) {
        draft.error = {
          msg: action.payload.msg,
        };
      } else {
        if (action.payload.type.length !== 0) {
          draft.error = {
            // msg: `서버와 통신중 오류(code: ${action.payload.errorCode})가 발생했습니다. 관리자에게 문의해주세요.`,
            msg: action.payload.msg
              ? action.payload.msg
              : `없음, errorCode: ${action.payload.errorCode}`,
          };
        } else {
          draft.error = {
            msg: action.payload.msg,
          };
        }
      }
    });
  });

export default loading;
