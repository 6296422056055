import InputDateType from "../inputs/InputDateType";
import { memo } from "react";

type Props = {
  startDate: string;
  max: string;
  startDateChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endDateChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endDate: string;
};

const FilterPeriod: React.FC<Props> = ({
  startDate,
  max,
  startDateChangeHandler,
  endDateChangeHandler,
  endDate,
}) => {
  return (
    <div className="filter_tool">
      <h5>Period</h5>

      <div className="date_wrap">
        <InputDateType
          value={startDate}
          max={max}
          onChangeHandler={startDateChangeHandler}
        />{" "}
        <span>~</span>{" "}
        <InputDateType
          value={endDate}
          min={startDate}
          max={max}
          onChangeHandler={endDateChangeHandler}
        />
      </div>
    </div>
  );
};

export default memo(FilterPeriod);
