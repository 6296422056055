import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { all } from "typed-redux-saga";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { useDispatch } from "react-redux";
import auth, { authSaga } from "./auth";
import loading, { actionSaga } from "./ui_loading";
import chat, { chatSaga } from "./chat";
import admin, { adminSaga } from "./admin";
import log, { logSaga } from "./log";
import model, { modelSaga } from "./model";
import { setContext } from "redux-saga/effects";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import myGpts, { myGptsSaga } from "./myGpts";
import alert, { alertSaga } from "./alert";

const customHistory = createBrowserHistory();

// router reducer, routerMiddleware, ConnectedRouter 모두 동일한 history instance 사용 해야 함
const { routerMiddleware, routerReducer, createReduxHistory } =
  createReduxHistoryContext({
    history: customHistory,

    //other options if needed
  });

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["userInfo", "teamsTheme"],
};
const rootReducer = combineReducers({
  router: routerReducer,
  auth: persistReducer(authPersistConfig, auth),
  chat,
  admin,
  log,
  model,
  myGpts,
  loading,
  alert,
});

// 루트 리듀서의 반환값를 유추해줍니다
// 추후 이 타입을 컨테이너 컴포넌트에서 불러와서 사용해야 하므로 내보내줍니다.
export type RootState = ReturnType<typeof rootReducer>;

// 루트 사가를 만들어서 내보내주세요.
export function* rootSaga() {
  yield setContext({ dispatch: store.dispatch });
  yield* all([
    authSaga(),
    chatSaga(),
    adminSaga(),
    logSaga(),
    modelSaga(),
    myGptsSaga(),
    actionSaga(),
    alertSaga(),
  ]);
}

const sagaMiddleware = createSagaMiddleware({
  context: {
    history: customHistory,
  },
});
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware, // for dispatching history actions
      sagaMiddleware
    )
  )
);

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

// 디스패치에 타입을 적용해서 정의 되지 않은 액션 사용하면 에러를 발생 시킴.
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
