import createAsyncSaga from "../../libs/createAsyncSaga";
import { all, takeLeading } from "typed-redux-saga";

import {
  DOWNLOAD_EVENT_LOG_DATA,
  DOWNLOAD_LOG_DATA,
  GET_BILLING_NAME_LIST,
  GET_EVENT_LOG_DATA,
  GET_LOG_DATA,
  GET_TOKEN_COMPANY,
  GET_TOKEN_LOG,
  downloadEventLogDataAsync,
  downloadLogDataAsync,
  getBillingNameListAsync,
  getEventLogDataAsync,
  getLogDataAsync,
  getTokenCompanyAsync,
  getTokenLogAsync,
} from "./actions";
import {
  downloadEventLogData,
  downloadLogData,
  getEventLogData,
  getLogData,
  getTokenCompany,
  getBillingNameList,
  getTokenLogList,
} from "./api";

const getLogDataSaga = createAsyncSaga(getLogDataAsync, getLogData);
const getEventLogDataSaga = createAsyncSaga(
  getEventLogDataAsync,
  getEventLogData
);

const downloadEventLogDataSaga = createAsyncSaga(
  downloadEventLogDataAsync,
  downloadEventLogData
);

const downloadLogDataSaga = createAsyncSaga(
  downloadLogDataAsync,
  downloadLogData
);

//! billing mgmt
const getTokenLogSaga = createAsyncSaga(getTokenLogAsync, getTokenLogList);
// const getTokenLogSaga = createAsyncSaga(getTokenLogAsync, getTokenLog);
const getTokenCompanySaga = createAsyncSaga(
  getTokenCompanyAsync,
  getTokenCompany
);

const getBillingNameListSaga = createAsyncSaga(
  getBillingNameListAsync,
  getBillingNameList
);

export function* logSaga() {
  //! log mgmt
  yield* all([yield* takeLeading(GET_LOG_DATA, getLogDataSaga)]);
  yield* all([yield* takeLeading(GET_EVENT_LOG_DATA, getEventLogDataSaga)]);
  yield* all([
    yield* takeLeading(DOWNLOAD_EVENT_LOG_DATA, downloadEventLogDataSaga),
  ]);
  yield* all([yield* takeLeading(DOWNLOAD_LOG_DATA, downloadLogDataSaga)]);

  //! billing mgmt
  yield* all([yield* takeLeading(GET_TOKEN_LOG, getTokenLogSaga)]);
  yield* all([yield* takeLeading(GET_TOKEN_COMPANY, getTokenCompanySaga)]);
  yield* all([
    yield* takeLeading(GET_BILLING_NAME_LIST, getBillingNameListSaga),
  ]);
}
