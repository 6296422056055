import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectTeamsTheme } from "../../../../features/auth";
import { memo } from "react";

type Props = {
  tabName: string;
  selectedTab: string;
  value: string;
  onClickHandler: (value: string) => void;
  pathName?: string;
  loading?: boolean;
};

const TabBtn: React.FC<Props> = ({
  tabName,
  selectedTab,
  value,
  onClickHandler,
  pathName,
  loading,
}) => {
  const teamsTheme = useSelector(selectTeamsTheme);

  return (
    <button
      className={`tab_btn tab_btn_${teamsTheme} ${
        selectedTab === value && "tab_select"
      }`}
      onClick={() => {
        if (loading) return;
        onClickHandler(value);
      }}
    >
      {pathName ? (
        <>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "progress",
              }}
            >
              <span>{tabName}</span>
            </div>
          ) : (
            <Link to={pathName}>{tabName}</Link>
          )}
        </>
      ) : (
        <span>{tabName}</span>
      )}
    </button>
  );
};

export default memo(TabBtn);
